import { orchestrator } from 'satcheljs';

import { runAnimationFrameAction } from '../action/run-animation-frame.action';
import { startRequestAction } from '../action/start-request.action';
import { setLastRuntimeRequestAction } from '../mutator-action/set-last-runtime-request.action';
import { setPausedRequestAction } from '../mutator-action/set-paused-request.action';
import { setRequestAction } from '../mutator-action/set-request.action';
import { requestAnimationFrameRefSelector } from '../selector/request-animation-frame-ref.selector';

orchestrator(startRequestAction, actionMessage => {
  const { requestId, request } = actionMessage;

  request.functionRef();
  setRequestAction(requestId, request);
  setLastRuntimeRequestAction(requestId, performance.now());
  setPausedRequestAction(requestId, false);

  if (requestAnimationFrameRefSelector() == null) {
    runAnimationFrameAction();
  }
});
