import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

import { IAnimationFrameStore } from './animation-frame.type';

const initStore: IAnimationFrameStore = {
  requests: new ObservableMap(),
  pausedRequests: new ObservableMap(),
  lastRunTimeRequests: new ObservableMap(),
  requestAnimationFrameRef: undefined
};

export const getAnimationFrameStore = createStore('animation-frame-store', initStore);
