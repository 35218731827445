import { mutatorAction } from 'satcheljs';

import { getAnimationFrameStore } from '../store/animation-frame.store';

export const setPausedRequestAction = mutatorAction('setPausedRequestAction', (requestId: string, paused: boolean | undefined) => {
  if (paused == null) {
    getAnimationFrameStore().pausedRequests.delete(requestId);
    return;
  }

  getAnimationFrameStore().pausedRequests.set(requestId, paused);
});
