import { mutatorAction } from 'satcheljs';

import { getAnimationFrameStore } from '../store/animation-frame.store';

export const setLastRuntimeRequestAction = mutatorAction('setLastRuntimeRequestAction', (requestId: string, time: number | undefined) => {
  if (time == null) {
    getAnimationFrameStore().lastRunTimeRequests.delete(requestId);
    return;
  }

  getAnimationFrameStore().lastRunTimeRequests.set(requestId, time);
});
