import { useCallback, useEffect, useMemo, useRef } from 'react';

import { clearRequestAction } from '../action/clear-request.action';
import { pauseRequestAction } from '../action/pause-request.action';
import { resumeRequestAction } from '../action/resume-request.action';
import { startRequestAction } from '../action/start-request.action';
import { isAvailableRequestSelector } from '../selector/is-available-request.selector';
import { isPausedRequestSelector } from '../selector/is-paused-request.selector';
import { lastRuntimeSelector } from '../selector/last-runtime.selector';
import { IAnimationFrameRequest } from '../store/animation-frame.type';

export const useAnimationFrame = (request: IAnimationFrameRequest) => {
  const pausedByVisibleChangeRef = useRef<boolean>(false);

  const memoizedRequest = useMemo(() => {
    return request;
  }, []);

  const isPaused = isPausedRequestSelector(memoizedRequest.id);
  const lastRuntime = lastRuntimeSelector(memoizedRequest.id);
  const isAvailable = isAvailableRequestSelector(memoizedRequest.id);

  const start = useCallback(() => {
    startRequestAction(memoizedRequest.id, memoizedRequest);
  }, [memoizedRequest]);

  const pause = useCallback(() => {
    pauseRequestAction(memoizedRequest.id);
  }, [memoizedRequest.id]);

  const resume = useCallback(() => {
    resumeRequestAction(memoizedRequest.id);
  }, [memoizedRequest.id]);

  const clear = useCallback(() => {
    clearRequestAction(memoizedRequest.id);
  }, [memoizedRequest.id]);

  useEffect(() => {
    const _eventHandler = () => {
      switch (document.visibilityState) {
        case 'visible': {
          if (pausedByVisibleChangeRef.current) {
            resume();
            pausedByVisibleChangeRef.current = false;
          }
          break;
        }

        case 'hidden': {
          if (!isPaused) {
            pause();
            pausedByVisibleChangeRef.current = true;
          }
          break;
        }

        default:
      }
    };
    document.addEventListener('visibilitychange', _eventHandler);

    return () => {
      document.removeEventListener('visibilitychange', _eventHandler);
    };
  }, [isPaused, pause, resume]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  return {
    start,
    pause,
    resume,
    clear,
    isPaused,
    lastRuntime,
    isAvailable
  };
};
