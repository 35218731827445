import { orchestrator } from 'satcheljs';

import { clearRequestAction } from '../action/clear-request.action';
import { setLastRuntimeRequestAction } from '../mutator-action/set-last-runtime-request.action';
import { setPausedRequestAction } from '../mutator-action/set-paused-request.action';
import { setRequestAnimationFrameRefAction } from '../mutator-action/set-request-animation-frame-ref.action';
import { setRequestAction } from '../mutator-action/set-request.action';
import { requestAnimationFrameRefSelector } from '../selector/request-animation-frame-ref.selector';
import { getAnimationFrameStore } from '../store/animation-frame.store';

orchestrator(clearRequestAction, actionMessage => {
  const { requestId } = actionMessage;

  setRequestAction(requestId, undefined);
  setPausedRequestAction(requestId, undefined);
  setLastRuntimeRequestAction(requestId, undefined);

  const _requestAnimationFrameRef = requestAnimationFrameRefSelector();
  if (getAnimationFrameStore().requests.size === 0 && _requestAnimationFrameRef != null) {
    window.cancelAnimationFrame(_requestAnimationFrameRef);
    setRequestAnimationFrameRefAction(undefined);
  }
});
