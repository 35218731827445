import { action } from 'satcheljs';

import { IAnimationFrameRequest } from '../store/animation-frame.type';

export const startRequestAction = action('startRequestAction', (requestId: string, request: IAnimationFrameRequest) => {
  return {
    requestId,
    request
  };
});
