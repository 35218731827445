import { orchestrator } from 'satcheljs';

import { runAnimationFrameAction } from '../action/run-animation-frame.action';
import { setLastRuntimeRequestAction } from '../mutator-action/set-last-runtime-request.action';
import { setRequestAnimationFrameRefAction } from '../mutator-action/set-request-animation-frame-ref.action';
import { isPausedRequestSelector } from '../selector/is-paused-request.selector';
import { lastRuntimeSelector } from '../selector/last-runtime.selector';
import { requestAnimationFrameRefSelector } from '../selector/request-animation-frame-ref.selector';
import { getAnimationFrameStore } from '../store/animation-frame.store';

orchestrator(runAnimationFrameAction, () => {
  /** check and run all requests */
  getAnimationFrameStore().requests.forEach((request, requestId) => {
    if (isPausedRequestSelector(requestId)) return;

    /** init last runtime if null */
    let _lastRunTime = lastRuntimeSelector(requestId);
    if (_lastRunTime == null) {
      _lastRunTime = performance.now();
      setLastRuntimeRequestAction(requestId, _lastRunTime);
    }

    /** check if elapsed time equal or greater than request delta time, then run function and update last run time */
    const _elapsedTime = performance.now() - _lastRunTime;
    if (_elapsedTime >= (request.deltaTime ?? 0)) {
      request.functionRef();
      setLastRuntimeRequestAction(requestId, performance.now());
    }
  });

  /** check call next frame run */
  if (getAnimationFrameStore().requests.size > 0) {
    setRequestAnimationFrameRefAction(window.requestAnimationFrame(runAnimationFrameAction));
  } else {
    /** if don't have any request in store, cancel request and clear it*/
    const _lastRequestAnimationFrameRef = requestAnimationFrameRefSelector();
    if (_lastRequestAnimationFrameRef != null) window.cancelAnimationFrame(_lastRequestAnimationFrameRef);

    setRequestAnimationFrameRefAction(undefined);
  }
});
