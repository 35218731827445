import { orchestrator } from 'satcheljs';

import { resumeRequestAction } from '../action/resume-request.action';
import { setLastRuntimeRequestAction } from '../mutator-action/set-last-runtime-request.action';
import { setPausedRequestAction } from '../mutator-action/set-paused-request.action';
import { getAnimationFrameStore } from '../store/animation-frame.store';

orchestrator(resumeRequestAction, actionMessage => {
  const { requestId } = actionMessage;

  if (getAnimationFrameStore().requests.has(requestId)) {
    getAnimationFrameStore().requests.get(requestId)?.functionRef();
    setPausedRequestAction(requestId, false);
    setLastRuntimeRequestAction(requestId, performance.now());
  } else {
    setPausedRequestAction(requestId, undefined);
    setLastRuntimeRequestAction(requestId, undefined);
  }
});
