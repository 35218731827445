import { mutatorAction } from 'satcheljs';

import { getAnimationFrameStore } from '../store/animation-frame.store';
import { IAnimationFrameRequest } from '../store/animation-frame.type';

export const setRequestAction = mutatorAction('setRequestAction', (requestId: string, request: IAnimationFrameRequest | undefined) => {
  if (!request) {
    getAnimationFrameStore().requests.delete(requestId);
    return;
  }

  getAnimationFrameStore().requests.set(requestId, request);
});
