import { orchestrator } from 'satcheljs';

import { pauseRequestAction } from '../action/pause-request.action';
import { setLastRuntimeRequestAction } from '../mutator-action/set-last-runtime-request.action';
import { setPausedRequestAction } from '../mutator-action/set-paused-request.action';
import { getAnimationFrameStore } from '../store/animation-frame.store';

orchestrator(pauseRequestAction, actionMessage => {
  const { requestId } = actionMessage;

  if (getAnimationFrameStore().requests.has(requestId)) {
    setPausedRequestAction(requestId, true);
  } else {
    setPausedRequestAction(requestId, undefined);
  }

  setLastRuntimeRequestAction(requestId, undefined);
});
