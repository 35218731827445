import { ObservableMap } from 'mobx';

export interface IAnimationFrameRequest {
  id: string;
  functionRef: () => void;
  deltaTime?: number;
}

export interface IAnimationFrameStore {
  requests: ObservableMap<string, IAnimationFrameRequest>;
  pausedRequests: ObservableMap<string, boolean>;
  lastRunTimeRequests: ObservableMap<string, number>;

  requestAnimationFrameRef: number | undefined;
}
